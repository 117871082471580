import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import ClaimsDashboard from './components/ClaimsDashboard';
import ClaimDetails from './components/ClaimDetails';

import PatientList from './components/PatientList';
import PatientDetails from './components/PatientDetails';

import Analytics from './components/Analytics';
import Settings from './components/Settings';

import NewCopayClaimForm from './components/CopayClaimForm';
import NewCheckForm from './components/CheckSubmissionForm';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ClaimsDashboard />} />
          <Route path="/claims" element={<ClaimsDashboard />} />
          <Route path="/claim/:id" element={<ClaimDetails />} />

          <Route path="/patients" element={<PatientList />} />
          <Route path="/patient/:id" element={<PatientDetails />} />

          <Route path="/analytics" element={<Analytics />} />
          <Route path="/settings" element={<Settings />} />

          <Route path="/claimSubmission" element={<NewCopayClaimForm />} />
          <Route path="/checkSubmission" element={<NewCheckForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;