import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClaimStatusButton from './ClaimStatusButton';
import ClaimBreakdownChart from './ClaimBreakdownChart';
import { Alert, Container, Paper, ThemeProvider, Typography } from '@mui/material';
import Navbar from './Navbar';
import { theme, colors } from '../Colors';
import axios from 'axios';

function ClaimInfo() {
    // const baseUrl = 'http://localhost:3000';
    const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

    const {id: claimId} = useParams();
    const [claimInfo, setClaimInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log(claimId);
        if (!claimId) {
            console.error("Error: claimId is undefined or empty");
            return;
        }
        const fetchClaimData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/claims/${claimId}`);
                console.log(response.data);
                setClaimInfo(response.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching claim data:", error);
                setLoading(false);
            }
        };
        fetchClaimData();
    }, [claimId]);

    if (loading) {
        return <Typography color = 'black'>Loading claim information...</Typography>;
    }

    if (error) {
        return <Typography color = 'black'>Error loading claim information: {error.message}</Typography>;
    }

    return (
        <div>
            <Container sx = {{ paddingBottom: '2%' }}>
                <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px', marginBottom: '10px' }} color={colors.tableHeadText}>
                    Claim Details for {claimInfo.patient.firstName} {claimInfo.patient.lastName} {' '}
                    {claimInfo.dateOfService && `(${new Date(claimInfo.dateOfService).toLocaleDateString('en-US')})`}
                </Typography>
                {claimInfo.status === "Claim Overdue" && (
                    <Alert severity="warning" style={{ marginBottom: '20px' }}>
                        <Typography><b>Automated Followup Triggered:</b> Since this claim has not been resolved in 1 month, Flair Health's agents are calling the payer to resolve the claim. An update will be added to the patient's profile when resolved.</Typography>
                    </Alert>
                )}
                {claimInfo.status === "Claim Denied" && (
                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                        <Typography><b>Claim Denial Followup Completed:</b> Since this claim was denied, Flair Health's agents automatically called the payer to appeal the claim. Information about the claim denial is below.</Typography>
                    </Alert>
                )}
                <Paper elevation={1} style={{ padding: '20px' }}>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Patient Name:</b> {claimInfo.patient.firstName} {claimInfo.patient.lastName}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Medication:</b> {claimInfo.medication}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Patient's Insurance Provider:</b> {claimInfo.patient.insuranceProvider} </Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Patient's Insurance Group:</b> {claimInfo.patient.insuranceGroup}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Patient's Insurance ID:</b> {claimInfo.patient.insuranceId}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Prescriber Name:</b> {claimInfo.prescriber.firstName} {claimInfo.prescriber.lastName}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>                    
                        <b>Status:</b> <ClaimStatusButton status={claimInfo.status} /> </Typography>
                </Paper>
                <Paper elevation={1} style={{ marginTop: '20px', padding: '20px' }}>
                    <ClaimBreakdownChart claims={claimInfo} />
                    <Typography style={{ textAlign: 'left', paddingTop: '10px', paddingBottom: '10px' }}>
                        <b>Case Number:</b> {claimInfo.caseNumber} 
                    </Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <a href="/files/EOB_Mia_Brown.pdf" download="EOB_Mia_Brown.pdf"> Download Claim PDF </a>
                    </Typography>
                </Paper>
                {claimInfo.patient.firstName === "James" && (
                    <Paper elevation={1} style={{ marginTop: '20px', padding: '20px' }}>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Note:</b> The claim was initially denied due to incomplete documentation. An automated follow-up was conducted with the payer and the missing information was submitted. The claim has since been approved by the payer with a reimbursement amount of $4500. The payment is expected to be processed within 5-7 business days. Please monitor the patient's account for updates.
                        </Typography>
                    </Paper>
                )}
                {claimInfo.patient.firstName === "Isabella" && (
                    <Paper elevation={1} style={{ marginTop: '20px', padding: '20px' }}>
                        <Typography style={{ textAlign: 'left', paddingBottom: '15px' }}>
                        <b>Claim Denial Information </b></Typography>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Representative Name:</b> Alex P </Typography>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Reference Number: </b> 139032737 </Typography>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Claim Number: </b> {claimInfo.caseNumber} </Typography>

                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Date Processed</b> 08/24/24 </Typography>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Denial Reason:</b> The insurance company denied the specialty pharmacy claim because proof of medical necessity was not included when the claim was submitted. This information is required to process the claim. </Typography>
                        <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                            <b>Additional Instructions:</b> Reach out to the prescribing physician and inform them that the claim was denied due to missing proof of medical necessity. Ensure that the Letter of Medical Necessity and any supporting documents are sent to the insurance company. </Typography>
                    </Paper>
                )}
            </Container>
        </div>
    )
}

export default function ClaimDetails() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <ClaimInfo/>
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}