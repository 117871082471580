import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Container, Button, ThemeProvider, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from '../Colors';
import dayjs from 'dayjs';
import axios from 'axios';
import Navbar from './Navbar';

// const baseUrl = 'http://localhost:3000';
const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

function CopayClaimForm() {
    const navigate = useNavigate();
    const { Buffer } = require('buffer');
    
    const [newPatient, setNewPatient] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        gender: '',
        birthDate: null,
        email: '',
        phone: '',
        prescriber: '',
        insuranceProvider: '',
        insuranceGroup: '',
        insuranceId: '',
        relationshipToInsured: '',
    });

    const [newClaim, setNewClaim] = useState({
        medication: '',
        eob: { data: Buffer, contentType: String }
    });
        
    const [prescribers, setPrescribers] = useState('');
    
    const [selectedPlanType, setSelectedInsurance] = useState('');
    const [selectedFund, setSelectedFund] = useState('');
    const [selectedDrug, setSelectedDrug] = useState('');
    const [EOBFileError, setEOBFileError] = useState(false);

    const insuranceOptions = ['Medicare/Medicaid', 'Commercial'];
    const fundOptions = {
        'Medicare/Medicaid': [
            'PAN Foundation',
            'HealthWell'
        ],
        'Commercial': [
            'AbbVie',
            'Amgen',
            'Astellas',
            'AstraZeneca',
            'Bayer',
            'Bristol Meyer Squibb',
            'Boehringer Ingelheim',
            'Eli Lilly',    
            'Exelixis',
            'Gilead',
            'Jazz Pharmaceuticals',
            'Johnson & Johnson',
            'Merck',
            'Mirum',
            'Novartis',
            'Pfizer',
            'Sanofi',
            'Sandoz',
            'Takeda',
            'Xephra',
        ]
    };
    
    const drugOptions = {
        'AbbVie': ['HUMIRA (adalimumab)'],
        'Amgen': ['AMJEVITA (adalimumab-atto)', 'AVSOLA (infliximab-axxq)', 'BLINCYTO (blinatumomab)', 'ENBREL (etanercept)', 'KRYSTEXXA (pegloticase)', 'LUMAKRAS (sotorasib)', 'OTEZLA (apremilast)', 'PROLIA (denosumab)', 'TAVNEOS (avacopan)', 'TEPEZZA (teprotumumab-trbw)', 'TEZSPIRE (tezepelumab-ekko)', 'XGEVA (denosumab)'],
        'Astellas': ['XTANDI (enzalutamide)', 'PADCEV (enfortumab vedotin)', 'XOSPATA (gilteritinib)'],
        'AstraZeneca': [],
        'Bayer': [],
        'Bristol Meyer Squibb': [],
        'Boehringer Ingelheim': [],
        'Eli Lilly': [],
        'Gilead': [],
        'Jazz Pharmaceuticals': [],
        'Johnson & Johnson': [],
        'Merck': [],
        'Novartis': [],
        'Pfizer': [],
        'Sanofi': [],
        'Sandoz': [],
        'Takeda': ['ALUNBRIG (brigatinib)', 'ICLUSIG (ponatinib)', 'FRUZAQLA (fruquintinib)', 'NINLARO (ixazomib)'],
        'Mirum': ['LIVMARLI (maralixibat)'],
        'Exelixis': ['CABOMETYX (cabozantinib)', 'COMETRIQ (cabozantinib)'],
        'PAN Foundation': ['AVASTIN (bevacizumab)', 'EYLEA (aflibercept)', 'INLYTA (axitinib)', 'HUMIRA (adalimumab)', 'XELJANZ (tofacitinib citrate)'],
        'HealthWell': ['Acute Myeloid Leukemia', 'Amyotrophic Lateral Sclerosis', 'Chronic Lymphocytic Leukemia', 
            'Cystic Fibrosis', 'Giant Cell Arteritis', 'Gout', 'Multiple Myeloma', 'Small Cell Lung Cancer']
    };

    useEffect(() => {
        const getPrescribers = async () => {
          try {
            const response = await axios.get(`${baseUrl}/api/prescribers`);
            setPrescribers(response.data);
            console.log(response.data);
          } catch (err) {
            console.log(err);
          }
        };
        getPrescribers();
    }, []);

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          [name]: value,
        }));
    };

    const handleInsuranceChange = (event) => {
        setSelectedInsurance(event.target.value);
        setSelectedFund('');
        setSelectedDrug('');
    };

    const handleFundChange = (event) => {
        setSelectedFund(event.target.value);
        setSelectedDrug('');
    };

    const handleDrugChange = (event) => {
        setSelectedDrug(event.target.value);
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          medication: event.target.value,
        }));
    };

    const handleDateChange = (date) => {
        setNewPatient((prevPatient) => ({
            ...prevPatient,
            birthDate: date ? dayjs(date) : null,
        }));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewClaim((prevClaim) => ({
                ...prevClaim,
                eob: {
                    data: file,
                    contentType: file.type
                }
            }));
        }
    };
    
    const handleSubmit = async () => {
        try {
            // create a new patient
            const patientResponse = await axios.post(`${baseUrl}/api/patients`, {
                firstName: newPatient.firstName,
                middleName: newPatient.middleName || null,
                lastName: newPatient.lastName,
                birthDate: newPatient.birthDate ? new Date(newPatient.birthDate) : null,
                email: newPatient.email,
                phone: newPatient.phone || null,
                address: newPatient.address || null,
                city: newPatient.city || null,
                state: newPatient.state || null,
                zip: newPatient.zip || null,
                gender: newPatient.gender || null,
                insuranceProvider: newPatient.insuranceProvider || null,
                insuranceGroup: newPatient.insuranceGroup || null,
                insuranceId: newPatient.insuranceId || null,
                relationshipToInsured: newPatient.relationshipToInsured || null,
                prescriber: newPatient.prescriber || null,
                clinic: newPatient.clinic || null,
            });
            
            console.log('Patient created successfully:', patientResponse.data);
            const patientId = patientResponse.data._id;

            //create a new claim
            const formData = new FormData();
            formData.append('patient', patientId);
            formData.append('prescriber', newPatient.prescriber);
            formData.append('medication', selectedDrug);
            formData.append('status', 'Pending Review');
            formData.append('caseNumber', `${Math.random().toString(36).substring(7).toUpperCase()}`);

            if (newClaim.eob && newClaim.eob.data) {
                formData.append('eob', newClaim.eob.data);
            }

            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            const claimResponse = await axios.post(`${baseUrl}/api/claims`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            console.log('Claim created successfully:', claimResponse.data);
            const claimId = claimResponse.data._id;

            await axios.put(`${baseUrl}/api/patients/${patientId}/claims`, {
                claimId: claimId
            });

            navigate(`/claim/${claimResponse.data._id}`);
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
        }
    };

    return (
        <div>
            <Typography variant="h5" style={{ color: '#1565c0', marginBottom: '10px' }}> Submit Assistance Claim </Typography>

            <div style={{ margin: '5px auto', width: '50rem' }}>
                <FormControl fullWidth style={{ marginTop: '10px' }}>
                    <InputLabel>Insurance Plan Type</InputLabel>
                    <Select value={selectedPlanType} onChange={handleInsuranceChange}>
                        {insuranceOptions.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {selectedPlanType && (
                <div style={{ margin: '5px auto', width: '50rem' }}>
                    <FormControl fullWidth style={{ marginTop: '10px' }}>
                        <InputLabel>Fund</InputLabel>
                        <Select value={selectedFund} onChange={handleFundChange}>
                            {fundOptions[selectedPlanType]?.map(option => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}

            {selectedFund && (
                <div style={{ margin: '5px auto', width: '50rem' }}>
                    <FormControl fullWidth style={{ marginTop: '10px' }}>
                        <InputLabel>Drug</InputLabel>
                        <Select value={selectedDrug} onChange={handleDrugChange}>
                            {drugOptions[selectedFund]?.map(option => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}

            <Divider sx={{ marginTop: '20px', marginBottom: '20px', backgroundColor: '#222', height: '2px' }} />

            {selectedDrug && (
                <div style={{ marginTop: '50px' }}> 
                    <div style={{ margin: '5px auto', width: '50rem' }}>
                        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
                        <TextField required label="First Name" name="firstName" value={newPatient.firstName}
                            onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                        <TextField label="Middle Name" name="middleName" value={newPatient.middleName}
                            onChange={handleTextChange} fullWidth style={{ marginLeft: '10px', marginBottom: '10px' }} />
                        <TextField required label="Last Name" name="lastName" value={newPatient.lastName}
                            onChange={handleTextChange} fullWidth style={{ marginLeft: '10px', marginBottom: '10px' }} />
                        </div>

                        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Date of Birth" value={newPatient.birthDate} onChange={handleDateChange} inputFormat="MM/DD/YYYY" 
                                renderInput={(params) => ( <TextField {...params} sx={{ marginBottom: '10px' }} InputLabelProps={{ shrink: true }}  /> )} />
                        </LocalizationProvider>
                        <FormControl fullWidth required style={{ marginLeft: '10px', marginBottom: '10px' }}>
                        <InputLabel>Gender</InputLabel>
                        <Select name="gender" value={newPatient.gender} onChange={handleTextChange} >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        </FormControl>
                        </div>
                        
                        <TextField required label="Home Address" name="address" value={newPatient.address}
                            onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />

                        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
                            <TextField required label="City" name="city" value={newPatient.city}
                                onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                            <TextField required label="State" name="state" value={newPatient.state}
                                onChange={handleTextChange} fullWidth style={{ marginLeft: '10px', marginBottom: '10px' }} />
                            <TextField required label="Zip Code" name="zip" value={newPatient.zip}
                                onChange={handleTextChange} fullWidth style={{marginLeft: '10px',  marginBottom: '10px' }} />
                        </div>

                        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
                            <TextField required label="Email" name="email" value={newPatient.email}
                                onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                            <TextField required label="Phone Number" name="phone" value={newPatient.phone}
                                onChange={handleTextChange} fullWidth style={{ marginLeft: '10px', marginBottom: '10px' }} />
                        </div>
                        
                        <FormControl fullWidth required style={{ marginBottom: '10px' }}>
                        <InputLabel>Prescriber</InputLabel>
                        <Select name="prescriber" value={newPatient.prescriber} onChange={handleTextChange} >
                            {prescribers.map(prescriber => (
                            <MenuItem key={prescriber._id} value={prescriber._id}>
                                {`${prescriber.firstName} ${prescriber.lastName}`}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl> 

                        <TextField required label="Insurance Provider" name="insuranceProvider" value={newPatient.insuranceProvider}
                            onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                        <TextField required label="Insurance Group" name="insuranceGroup" value={newPatient.insuranceGroup}
                            onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                        <TextField required label="Insurance ID" name="insuranceId" value={newPatient.insuranceId}
                            onChange={handleTextChange} fullWidth style={{ marginBottom: '10px' }} />
                        
                        <FormControl fullWidth required style={{ marginBottom: '10px' }}>
                        <InputLabel>Relationship to Insured</InputLabel>
                        <Select name="relationshipToInsured" value={newPatient.relationshipToInsured} onChange={handleTextChange} >
                            <MenuItem value="Self">Self</MenuItem>
                            <MenuItem value="Spouse">Spouse</MenuItem>
                            <MenuItem value="Child">Child</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        </FormControl>

                        <FormControl fullWidth required style={{ marginBottom: '10px' }}>
                            <Button variant="contained" component="label" style={{ marginBottom: '10px' }}>
                                Upload Explanation of Benefits (EOB) or Remittance Advice (RA)
                                <input type="file" hidden onChange={handleFileUpload} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"/>
                            </Button>
                            {(newClaim.eob && newClaim.eob.data) && (
                                <Typography color="black" style={{ marginTop: '10px' }}>
                                    Uploaded file: {newClaim.eob.data.name}
                                </Typography>
                            )}
                        </FormControl>
                    </div>
                </div>
            )}

            {EOBFileError && (
                <Typography color="error" style={{ marginBottom: '10px' }}>
                    File upload is required.
                </Typography>
            )}

            <div style={{ margin: '10px auto', width: '20rem' }}>
                <Button variant={'contained'} onClick={handleSubmit}>Submit Claim</Button>
            </div>
        </div>
    );
}

export default function NewCopayClaimForm() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <CopayClaimForm />
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}