import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, TextField, Typography, Paper } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TablePagination } from '@mui/material';
import { colors } from '../Colors';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Navbar from './Navbar';

const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: colors.tableHeadBackground,
    color: colors.tableHeadText
}));

// const baseUrl = 'http://localhost:3000';
const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

export default function PatientList() {
    const navigate = useNavigate();

    const [patients, setPatients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
      const getPatients = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/patients`);
          const sortedPatients = response.data.sort((a, b) => {
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
            return 0;
          });
          setPatients(sortedPatients);
          console.log(response.data);
        } catch (err) {
          console.log(err);
        }
      };
      getPatients();
    }, []);

    const searchedPatients = patients.filter(patient => {
        const birthDateString = new Date(patient.birthDate).toLocaleDateString();
        return (
            patient.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            patient.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            birthDateString.includes(searchQuery) ||
            patient.medication.toLowerCase().includes(searchQuery.toLowerCase()) ||
            patient.prescriber.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            patient.prescriber.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            patient.insuranceProvider.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });
      
    const pagePatients = searchedPatients.slice(startIndex, endIndex);

    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
          <div className="App">
            <Container sx = {{ paddingBottom: '2%'}}>
                <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Patient List </Typography>
                <TextField label="Search Patients" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />
            </Container>
            
            <TableContainer component={Paper} sx={{ width: '80%' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell><Typography variant="body1">First Name</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body1">Last Name</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body1">DOB</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body1">Prescriber</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body1">Insurance</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body1">Claims</Typography></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagePatients.map(patient => (
                    <TableRow key={patient._id}>
                      <TableCell>{patient.firstName}</TableCell>
                      <TableCell>{patient.lastName}</TableCell>
                      <TableCell>{new Date(patient.birthDate).toLocaleDateString()}</TableCell>
                      <TableCell>{patient.prescriber.firstName} {patient.prescriber.lastName}</TableCell>
                      <TableCell>{patient.insuranceProvider}</TableCell>
                      <TableCell><Button onClick={() => navigate(`/patient/${patient._id}` )}>Open</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination component="div" count={patients.length}
                rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} 
                rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}
                page={page} onPageChange={handleChangePage} />
            </TableContainer>
  
            <Container sx = {{ paddingBottom: '3%' }}> </Container>
          </div>
        </div>
    )
}