import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Box, Button, Container, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClaimStatusButton from './ClaimStatusButton';
import ClaimBreakdownChart from './ClaimBreakdownChart';
import Navbar from './Navbar';
import { theme, colors } from '../Colors';
import axios from 'axios';

function PatientClaimsInfo() {
    const navigate = useNavigate();

    // const baseUrl = 'http://localhost:3000';
    const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

    const {id: patientId} = useParams();
    const [patientInfo, setPatientInfo] = useState([]);
    const [patientClaims, setPatientClaims] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const calculateTotals = (claims) => {
        return claims.reduce((totals, claim) => {
            totals.totalAmount += claim.totalAmount || 0;
            totals.paidAmount += claim.paidAmount || 0;
            totals.unpaidAmount += claim.unpaidAmount || 0;
            totals.insuranceRespAmt += claim.insuranceRespAmt || 0;
            totals.pharmaRespAmt += claim.pharmaRespAmt || 0;
            totals.patientRespAmt += claim.patientRespAmt || 0;
            return totals;
        }, {
            totalAmount: 0,
            paidAmount: 0,
            unpaidAmount: 0,
            insuranceRespAmt: 0,
            pharmaRespAmt: 0,
            patientRespAmt: 0
        });
    };

    const totals = calculateTotals(patientClaims);

    useEffect(() => {
        if (!patientId) {
            console.error("Error: patientId is undefined or empty");
            return;
        }
        const fetchPatientData = async () => {
            try {
                const patientResponse = await axios.get(`${baseUrl}/api/patients/${patientId}`);
                const claimsResponse = await axios.get(`${baseUrl}/api/patients/${patientId}/claims`);
                setPatientInfo(patientResponse.data);
                setPatientClaims(claimsResponse.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchPatientData();
    }, [patientId]);

    const handleClaimDetails = (claimId) => {
        navigate(`/claim/${claimId}`);
    };

    if (loading) {
        return <Typography color = 'black'>Loading patient information...</Typography>;
    }

    if (error) {
        return <Typography color = 'black'>Error loading patient information: {error.message}</Typography>;
    }

    return (
        <div>
            <Container sx = {{ paddingBottom: '2%' }}>
                <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px', marginBottom: '10px' }} color={colors.tableHeadText}>
                    Patient Information for {patientInfo.firstName} {patientInfo.lastName}
                </Typography>
                <Paper elevation={1} style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>First Name:</b> {patientInfo.firstName}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Middle Name:</b> {patientInfo.middleName}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Last Name:</b> {patientInfo.lastName}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Gender:</b> {patientInfo.gender}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Birth Date:</b> {new Date(patientInfo.birthDate).toLocaleDateString()}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Prescriber:</b> {patientInfo.prescriber.firstName} {patientInfo.prescriber.lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Address:</b> {patientInfo.address}, {patientInfo.city}, {patientInfo.state} {patientInfo.zip}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Email:</b> {patientInfo.email}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Phone:</b> {patientInfo.phone}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Insurance Provider:</b> {patientInfo.insuranceProvider}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Insurance Group:</b> {patientInfo.insuranceGroup}
                            </Typography>
                            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                <b>Insurance ID:</b> {patientInfo.insuranceId}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={1} style={{ marginTop: '20px', padding: '20px' }}>
                    <Box style={{ textAlign: 'left', paddingBottom: '30px' }} >
                        <Typography variant="h6" style={{ textAlign: 'left', paddingBottom: '10px', marginBottom: '10px' }} color={colors.tableHeadText}>
                            Claims
                        </Typography>
                        <ClaimBreakdownChart claims={patientClaims} />
                    </Box>
                    {patientClaims.length === 0 ? (
                        <Typography>No claims available for this patient.</Typography>
                    ) : (
                        patientClaims.map((claim, index) => (
                            <Accordion key={claim.claimId} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box sx={{ display: 'flex', width: '100%', paddingRight: '5px', paddingLeft: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body1">
                                            <b>{claim.medication}</b> (Service Date: {new Date(claim.dateOfService).toLocaleDateString()})
                                        </Typography>
                                        <ClaimStatusButton status={claim.status} />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                        <b>Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.totalAmount)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                        <b>Unpaid Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.unpaidAmount)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                        <b>Paid Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.paidAmount)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingLeft: '20px', paddingBottom: '10px' }}>
                                        <b>Insurance Paid Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.insuranceRespAmt)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingLeft: '20px', paddingBottom: '10px' }}>
                                        <b>Manufacturer/Foundation Paid Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.pharmaRespAmt)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingLeft: '20px', paddingBottom: '10px' }}>
                                        <b>Patient Paid Amount:</b> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.patientRespAmt)}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                                        <b>Case Number:</b> {claim.caseNumber} </Typography>
                                    <Button variant="contained" onClick={() => handleClaimDetails(claim._id)} color="primary">
                                        View More Details
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    )}
                </Paper>
            </Container>
        </div>
    )
}

export default function PatientDetails() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <PatientClaimsInfo/>
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}