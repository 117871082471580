import React from 'react';
import { Button } from '@mui/material';

const ClaimStatusButton = ({ status }) => {
  let backgroundColor = '';
  switch (status) {
    case 'Claim Overdue':
      backgroundColor = 'darkblue';
      break;
    case 'Pending Review':
      backgroundColor = 'darkgoldenrod';
      break;
    case 'Claim Denied':
      backgroundColor = 'darkred';
      break;
    case 'Claim Approved':
      backgroundColor = 'green';
      break;
    case 'Funds Received':
      backgroundColor = 'darkgreen';
      break;
    default:
      return <span>{status}</span>;
  }

  return (
    <Button 
      variant="contained" 
      style={{ 
        fontSize: '0.75rem', 
        backgroundColor, 
        color: 'white', 
        borderRadius: '16px', 
        pointerEvents: 'none', 
        whiteSpace: 'nowrap' 
      }}
    >
      {status}
    </Button>
  );
};

export default ClaimStatusButton;