import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, FormControl, Select, MenuItem, Typography, Button, Container, Paper } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { colors } from '../Colors';
import Navbar from './Navbar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Analytics() {
    const [dateRange, setDateRange] = useState('all');

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const totalAmount = 44_000_000; // Hardcoded total amount
    const unpaidAmount = 16_000_000; // Hardcoded unpaid amount
    const insurancePaidAmount = 20_000_000; // Hardcoded insurance paid amount
    const pharmaPaidAmount = 5_000_000; // Hardcoded pharma paid amount
    const patientPaidAmount = 3_000_000; // Hardcoded patient paid amount

    const percentageUnpaid = ((unpaidAmount / totalAmount) * 100).toFixed(2);

    const labels = ['All Claims'];

    const data = {
        labels,
        datasets: [
            { label: 'Unpaid Amount', data: [unpaidAmount], backgroundColor: 'gray' },
            { label: 'Paid by Insurance', data: [insurancePaidAmount], backgroundColor: 'dodgerblue' },
            { label: 'Paid by Pharma/Foundation', data: [pharmaPaidAmount], backgroundColor: 'lightgreen' },
            { label: 'Paid by Patient', data: [patientPaidAmount], backgroundColor: 'orange' }
        ]
    };

    const options = {
        indexAxis: 'y',
        scales: {
            x: { stacked: true, title: { display: true, text: 'Amount (USD)' }},
            y: { stacked: true }
        },
        plugins: {
            legend: { position: 'bottom', labels: { usePointStyle: true }},
            datalabels: { display: true, color: 'black', anchor: 'center', align: 'center',
                formatter: (value) => `$${value.toLocaleString('en-US')}`, font: { size: 12 },
            },
        },
        responsive: true,
        maintainAspectRatio: false
    };

    const downloadReport = () => {
        const csvContent = `data:text/csv;charset=utf-8,
        Category,Amount\n
        Total Amount,${totalAmount}\n
        Unpaid Amount,${unpaidAmount}\n
        Paid by Insurance,${insurancePaidAmount}\n
        Paid by Pharma/Foundation,${pharmaPaidAmount}\n
        Paid by Patient,${patientPaidAmount}\n`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'claim_report.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <div style={{ display: 'flex' }}>
            <Navbar />
            <div className="App">
                <Container sx={{ paddingBottom: '2%' }}>
                    <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Analytics
                    </Typography>
                </Container>

                {/* Claim Breakdown Chart Section */}
                <Container sx={{ paddingBottom: '3%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
                        <FormControl sx={{ width: '150px', height: '40px', marginBottom: '10px' }}>
                            <Select value={dateRange} onChange={handleDateRangeChange}>
                                <MenuItem value="all">All Claims</MenuItem>
                                <MenuItem value="30">Last 30 Days</MenuItem>
                                <MenuItem value="90">Last 90 Days</MenuItem>
                                <MenuItem value="180">Last 180 Days</MenuItem>
                                <MenuItem value="365">Last 365 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
                        <Bar data={data} options={options} />
                    </Box>

                    <Typography style={{ color: 'black', textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Total Amount: </b> 
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)}
                    </Typography>
                    <Typography style={{ color: 'black', textAlign: 'left', paddingBottom: '10px' }}>
                        <b>Percentage of Revenue Unpaid: </b> {percentageUnpaid}%
                    </Typography>
                    

                    <Paper elevation={1} style={{ padding: '20px', marginTop: '20px' }}>
                      <Typography style={{ color: 'black', textAlign: 'left', paddingBottom: '10px' }}>
                          <b>Opportunities to Increase Revenue Capture</b>
                      </Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{ color: 'black', textAlign: 'left' }}>
                                                <b>131 Denied Claims:</b> Follow up on $3,200,071
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" sx={{ backgroundColor: '#293593', color: 'white'}} endIcon={<ArrowForwardIcon />}>
                                                View Report
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{ color: 'black', textAlign: 'left' }}>
                                                <b>45 Overdue Claims:</b> Follow up on $1,230,456
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" sx={{ backgroundColor: '#293593', color: 'white'}} endIcon={<ArrowForwardIcon />}>
                                                View Report
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{ color: 'black', textAlign: 'left' }}>
                                                <b>Low Reimbursement for Adalimumab Biosimilars:</b> Investigate payer scorecards for this drug.
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                          <Button variant="contained" sx={{ backgroundColor: '#293593', color: 'white'}} endIcon={<ArrowForwardIcon />}>
                                                View Report
                                          </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container>
            </div>
        </div>
    );
}