import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ClaimBreakdownChart = ({ claims }) => {
    const isSingleClaim = !Array.isArray(claims);
    const [filteredClaims, setFilteredClaims] = useState(claims);
    const [dateRange, setDateRange] = useState('all');

    const filterClaimsByDate = (rangeInDays) => {
        const now = new Date();
        const pastDate = new Date(now);
        pastDate.setDate(now.getDate() - rangeInDays);

        return claims.filter(claim => {
            const claimDate = new Date(claim.dateOfService);
            return claimDate >= pastDate && claimDate <= now;
        });
    };

    useEffect(() => {
        if (!isSingleClaim) {
            if (dateRange === 'all') {
                setFilteredClaims(claims);
            } else {
                setFilteredClaims(filterClaimsByDate(parseInt(dateRange)));
            }
        }
    }, [dateRange, claims, isSingleClaim]);

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const claimData = isSingleClaim ? [claims] : filteredClaims;

    const totalClaim = claimData.reduce(
        (totals, claim) => ({
            totalAmount: (totals.totalAmount || 0) + claim.totalAmount,
            paidAmount: (totals.paidAmount || 0) + (claim.paidAmount || 0),
            unpaidAmount: (totals.unpaidAmount || 0) + (claim.unpaidAmount || 0),
            insuranceRespAmt: (totals.insuranceRespAmt || 0) + (claim.insuranceRespAmt || 0),
            pharmaRespAmt: (totals.pharmaRespAmt || 0) + (claim.pharmaRespAmt || 0),
            patientRespAmt: (totals.patientRespAmt || 0) + (claim.patientRespAmt || 0),
        }),
        {}
    );

    const labels = isSingleClaim 
        ? [`Claim ${claims.dateOfService ? `(${new Date(claims.dateOfService).toLocaleDateString('en-US')})` : ''}`] 
        : ['All Claims'];

    const data = {
        labels,
        datasets: [
            { label: 'Unpaid Amount', data: [totalClaim.unpaidAmount], backgroundColor: 'gray' },
            { label: 'Paid by Insurance', data: [totalClaim.insuranceRespAmt],backgroundColor: 'dodgerblue' },
            { label: 'Paid by Pharma/Foundation', data: [totalClaim.pharmaRespAmt], backgroundColor: 'lightgreen' },
            { label: 'Paid by Patient', data: [totalClaim.patientRespAmt], backgroundColor: 'orange' }
        ]
    };

    const options = {
        indexAxis: 'y',
        scales: {
            x: { stacked: true, title: { display: true, text: 'Amount (USD)' }},
            y: { stacked: true }
        },
        plugins: {
            legend: { position: 'bottom', labels: { usePointStyle: true }},
            datalabels: { display: true, color: 'black', anchor: 'center', align: 'center',
                formatter: (value) => `$${value.toLocaleString('en-US')}`, font: { size: 12 },
            },
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div>
            {!isSingleClaim && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
                    <FormControl sx={{ width: '150px', height: '40px', marginBottom: '10px' }}>
                        <Select value={dateRange} onChange={handleDateRangeChange}>
                            <MenuItem value="all">All Claims</MenuItem>
                            <MenuItem value="30">Last 30 Days</MenuItem>
                            <MenuItem value="90">Last 90 Days</MenuItem>
                            <MenuItem value="180">Last 180 Days</MenuItem>
                            <MenuItem value="365">Last 365 Days</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            )}
            <Box sx={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
                <Bar data={data} options={options} />
            </Box>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Total Amount' : `Total Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.totalAmount)}
            </Typography>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Unpaid Amount' : `Total Unpaid Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.unpaidAmount)}
            </Typography>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Paid Amount' : `Total Paid Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.paidAmount)}
            </Typography>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Insurance Paid Amount' : `Insurance Paid Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.insuranceRespAmt)}
            </Typography>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Manufacturer/Foundation Paid Amount' : `Manufacturer/Foundation Paid Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.pharmaRespAmt)}
            </Typography>
            <Typography style={{ textAlign: 'left', paddingBottom: '10px' }}>
                <b>{isSingleClaim ? 'Patient Paid Amount' : `Patient Paid Amount (All Claims - Last ${dateRange === 'all' ? 'All' : dateRange} Days)`}: </b> 
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalClaim.patientRespAmt)}
            </Typography>
        </div>
    );
};

export default ClaimBreakdownChart;