import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ClaimStatusButton from './ClaimStatusButton';
import { Box, Button, Container, Typography, TextField, Paper } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar'

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: colors.tableHeadBackground,
  color: colors.tableHeadText
}));

// const baseUrl = 'http://localhost:000';
const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

export default function ClaimsDashboard() {    
    const navigate = useNavigate();

    const [claims, setClaims] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
      const getClaims = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/claims`);
          const sortedClaims = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setClaims(sortedClaims);
        } catch (err) {
          console.log(err);
        }
      };
      getClaims();
    }, []);
    
    const searchedClaims = claims.filter(claim => {
      const birthDateString = new Date(claim.patient.birthDate).toLocaleDateString();
      return (
          claim.patient.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          claim.patient.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          birthDateString.includes(searchQuery) ||
          claim.medication.toLowerCase().includes(searchQuery.toLowerCase()) ||
          claim.prescriber.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          claim.prescriber.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          claim.patient.insuranceProvider.toLowerCase().includes(searchQuery.toLowerCase()) ||
          claim.status.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    
    const pageClaims = searchedClaims.slice(startIndex, endIndex);

    const handleSearch = event => {
      setSearchQuery(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleNewClaimForm = () => {
      navigate('/claimSubmission');
    };

    const handleNewCheckForm = () => {
      navigate('/checkSubmission');
    };

    return (
      <div style={{ display: 'flex' }}>
      <Navbar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
              <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Claims Dashboard </Typography>
              <TextField label="Search Claims" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button variant='contained' sx={{ backgroundColor: '#293593', color: 'white'}} onClick={handleNewClaimForm}> Submit Assistance Claim</Button>
                <Button variant='contained' sx={{ backgroundColor: '#293593', color: 'white'}} onClick={handleNewCheckForm}> Upload Check </Button>
              </Box>
          </Container>
          
          <TableContainer component={Paper} sx={{ width: '80%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell><Typography variant="body1">First Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Last Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Date of Service</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Medication</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Prescriber</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Insurance</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Status</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Details</Typography></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageClaims.map(claim => (
                  <TableRow key={claim._id}>
                    <TableCell>{claim.patient.firstName}</TableCell>
                    <TableCell>{claim.patient.lastName}</TableCell>
                    <TableCell>{new Date(claim.dateOfService).toLocaleDateString()}</TableCell>
                    <TableCell>{claim.medication}</TableCell>
                    <TableCell>{claim.prescriber.firstName} {claim.prescriber.lastName}</TableCell>
                    <TableCell>{claim.patient.insuranceProvider}</TableCell>
                    <TableCell> <ClaimStatusButton status={claim.status} /> </TableCell>
                    <TableCell><Button onClick={() => navigate(`/claim/${claim._id}` )}>Open</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination component="div" count={claims.length}
              rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} 
              rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}
              page={page} onPageChange={handleChangePage} />
          </TableContainer>

          <Container sx = {{ paddingBottom: '3%' }}> </Container>
        </div>
      </div>
    )
}

// export default function ClaimsDashboard() {    
//     const navigate = useNavigate();

//     const [claims, setClaims] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const startIndex = page * rowsPerPage;
//     const endIndex = startIndex + rowsPerPage;

//     // Filter states
//     const [statusFilter, setStatusFilter] = useState('');
//     const [insuranceFilter, setInsuranceFilter] = useState('');
//     const [medicationFilter, setMedicationFilter] = useState('');

//     useEffect(() => {
//       const getClaims = async () => {
//         try {
//           const response = await axios.get(`${baseUrl}/api/claims`);
//           const sortedClaims = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//           setClaims(sortedClaims);
//         } catch (err) {
//           console.log(err);
//         }
//       };
//       getClaims();
//     }, []);
    
//     const searchedClaims = claims.filter(claim => {
//       const birthDateString = new Date(claim.patient.birthDate).toLocaleDateString();
      
//       // Apply filters (search query and filters)
//       const matchesSearch = (
//         claim.patient.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         claim.patient.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         birthDateString.includes(searchQuery) ||
//         claim.medication.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         claim.prescriber.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         claim.prescriber.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         claim.patient.insuranceProvider.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         claim.status.toLowerCase().includes(searchQuery.toLowerCase())
//       );

//       const matchesStatusFilter = statusFilter ? claim.status === statusFilter : true;
//       const matchesInsuranceFilter = insuranceFilter ? claim.patient.insuranceProvider === insuranceFilter : true;
//       const matchesMedicationFilter = medicationFilter ? claim.medication === medicationFilter : true;

//       // Return claim that matches search and all filters
//       return matchesSearch && matchesStatusFilter && matchesInsuranceFilter && matchesMedicationFilter;
//     });
    
//     const pageClaims = searchedClaims.slice(startIndex, endIndex);

//     const handleSearch = event => {
//       setSearchQuery(event.target.value);
//     };

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     const handleNewClaimForm = () => {
//       navigate('/claimSubmission');
//     };

//     const handleNewCheckForm = () => {
//       navigate('/checkSubmission');
//     };

//     return (
//       <div style={{ display: 'flex' }}>
//       <Navbar />
//         <div className="App">
//           <Container sx = {{ paddingBottom: '2%'}}>
//               <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Claims Dashboard </Typography>

//               {/* Search and Filters */}
//               <TextField label="Search Claims" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />

//               <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
//                 {/* Status Filter */}
//                 <FormControl fullWidth>
//                   <InputLabel>Status</InputLabel>
//                   <Select value={statusFilter} label="Status" onChange={(e) => setStatusFilter(e.target.value)}>
//                     <MenuItem value="">All</MenuItem>
//                     <MenuItem value="Claim Submitted">Claim Submitted</MenuItem>
//                     <MenuItem value="Pending">Pending</MenuItem>
//                     <MenuItem value="Claim Denied">Claim Denied</MenuItem>
//                     <MenuItem value="Claim Approved">Claim Approved</MenuItem>
//                     <MenuItem value="Funds Received">Funds Received</MenuItem>
//                   </Select>
//                 </FormControl>

//                 {/* Insurance Filter */}
//                 <FormControl fullWidth>
//                   <InputLabel>Insurance Provider</InputLabel>
//                   <Select value={insuranceFilter} label="Insurance Provider" onChange={(e) => setInsuranceFilter(e.target.value)}>
//                     <MenuItem value="">All</MenuItem>
//                     {claims.map(claim => (
//                       <MenuItem key={claim.patient.insuranceProvider} value={claim.patient.insuranceProvider}>
//                         {claim.patient.insuranceProvider}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>

//                 {/* Medication Filter */}
//                 <FormControl fullWidth>
//                   <InputLabel>Medication</InputLabel>
//                   <Select value={medicationFilter} label="Medication" onChange={(e) => setMedicationFilter(e.target.value)}>
//                     <MenuItem value="">All</MenuItem>
//                     {claims.map(claim => (
//                       <MenuItem key={claim.medication} value={claim.medication}>
//                         {claim.medication}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Box>

//               <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
//                 <Button variant='contained' sx={{ backgroundColor: '#293593', color: 'white'}} onClick={handleNewClaimForm}> Submit Assistance Claim</Button>
//                 <Button variant='contained' sx={{ backgroundColor: '#293593', color: 'white'}} onClick={handleNewCheckForm}> Upload Check </Button>
//               </Box>
//           </Container>
          
//           <TableContainer component={Paper} sx={{ width: '80%' }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell><Typography variant="body1">First Name</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Last Name</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Date of Service</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Medication</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Prescriber</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Insurance</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Status</Typography></StyledTableCell>
//                   <StyledTableCell><Typography variant="body1">Details</Typography></StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {pageClaims.map(claim => (
//                   <TableRow key={claim._id}>
//                     <TableCell>{claim.patient.firstName}</TableCell>
//                     <TableCell>{claim.patient.lastName}</TableCell>
//                     <TableCell>{new Date(claim.dateOfService).toLocaleDateString()}</TableCell>
//                     <TableCell>{claim.medication}</TableCell>
//                     <TableCell>{claim.prescriber.firstName} {claim.prescriber.lastName}</TableCell>
//                     <TableCell>{claim.patient.insuranceProvider}</TableCell>
//                     <TableCell> <ClaimStatusButton status={claim.status} /> </TableCell>
//                     <TableCell><Button onClick={() => navigate(`/claim/${claim._id}` )}>Open</Button></TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//             <TablePagination component="div" count={searchedClaims.length}
//               rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} 
//               rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}
//               page={page} onPageChange={handleChangePage} />
//           </TableContainer>

//           <Container sx = {{ paddingBottom: '3%' }}> </Container>
//         </div>
//       </div>
//     )
// }