import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, FormHelperText, FormGroup } from '@mui/material';
import { InputLabel, Select, MenuItem, Container, Checkbox, Button, ThemeProvider, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { theme } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar';

// const baseUrl = 'http://localhost:3000';
const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

function CheckForm() {
    const navigate = useNavigate();

    const [newPatient, setNewPatient] = useState({
        firstName: '',
        lastName: '',
        birthDate: null,
        zipCode: '',
        email: '',
        phone: '',
        insuranceProvider: '',
        insuranceGroup: '',
        insuranceId: '',
        hcpName: '',
        frmName: '',
        medication: '',
        referralNotes: '',
        consent: false
    });

    const [isFirstNameError, setFirstNameError] = useState(false);
    const [isLastNameError, setLastNameError] = useState(false);
    const [isBirthDateError, setBirthDateError] = useState(false);
    const [isZipCodeError, setZipCodeError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isInsuranceError, setInsuranceError] = useState(false);
    const [isHCPNameError, setHCPNameError] = useState(false);
    const [isFRMNameError, setFRMNameError] = useState(false);
    const [isMedicationError, setMedicationError] = useState(false);
    const [isReferralNotesError, setReferralNotesError] = useState(false);
    const [isConsentError, setConsentError] = useState(false);

    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [birthDateHelperText, setBirthDateHelperText] = useState("");
    const [zipCodeHelperText, setZipCodeHelperText] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [phoneHelperText, setPhoneHelperText] = useState("");
    const [insuranceHelperText, setInsuranceHelperText] = useState("");
    const [hcpNameHelperText, setHCPNameHelperText] = useState("");
    const [frmNameHelperText, setFRMNameHelperText] = useState("");
    const [medicationHelperText, setMedicationHelperText] = useState("");
    const [referralNotesHelperText, setReferralNotesHelperText]  = useState("");
    const [consentHelperText, setConsentHelperText] = useState(false);

    const handleAddPatient = async () => {
        try {
            var valid = true;
            if (newPatient.firstName === null || newPatient.firstName === '') {
                setFirstNameError(true);
                setFirstNameHelperText('Please enter a valid first name.');
                valid = false;
            }
            if (newPatient.lastName === null || newPatient.lastName === '') {
                setLastNameError(true);
                setLastNameHelperText('Please enter a valid last name.');
                valid = false;
            }
            if (newPatient.birthDate === null || newPatient.birthDate === '') {
                setBirthDateError(true);
                setBirthDateHelperText('Please enter a valid birthdate.');
                valid = false;
            }
            if (newPatient.zipCode === null || newPatient.zipCode === '') {
                setZipCodeError(true);
                setZipCodeHelperText('Please enter patient\'s zip code.');
                valid = false;
            }
            if (newPatient.email === null || newPatient.email === '') {
                setIsEmailError(true);
                setEmailHelperText('Please enter a valid email.');
                valid = false;
            }
            if (newPatient.phone === null || newPatient.phone === '') {
                setIsPhoneError(true);
                setPhoneHelperText('Please enter a valid phone number.');
                valid = false;
            }
            if (newPatient.insuranceProvider === null || newPatient.insuranceProvider === '' ||
                newPatient.insuranceGroup === null || newPatient.insuranceGroup === '' ||
                newPatient.insuranceId === null || newPatient.insuranceId === '') {
                setInsuranceError(true);
                setInsuranceHelperText('Please enter valid insurance information.');
                valid = false;
            }
            if (newPatient.hcpName === null || newPatient.hcpName === '') {
                setHCPNameError(true);
                setHCPNameHelperText('Please enter a valid HCP name.');
                valid = false;
            }
            if (newPatient.frmName === null || newPatient.frmName === '') {
                setFRMNameError(true);
                setFRMNameHelperText('Please select a valid FRM.');
                valid = false;
            }
            if (newPatient.medication === null || newPatient.medication === '') {
                setMedicationError(true);
                setMedicationHelperText('Please select a valid medication.');
                valid = false;
            }
            if (newPatient.referralNotes === null) {
                setReferralNotesError(true);
                setReferralNotesHelperText('Please enter any referral notes.');
                valid = false;
            }
            if (!(newPatient.consent)) {
                setConsentError(true);
                setConsentHelperText('Please indicate the patient\'s consent to enroll in the program.');
                valid = false;
            }
            if (!valid) return;
            const response = await axios.post(`${baseUrl}/api/patients`, newPatient);
            console.log(response.data);
            navigate(`patients`);
        } catch (err) {
          console.error('Error:', err);
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          birthDate: date,
        }));
    };

    const handleBoolChange = (event) => {
        const { name, checked } = event.target;
        setNewPatient((prevPatient) => ({
            ...prevPatient,
            [name]: checked,
        }));
    };

    return (
        <div>
            <Typography variant="h5" style={{ color: '#1565c0', marginBottom: '10px' }}>Flair Health – New Patient</Typography>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="First Name" name="firstName" value={newPatient.firstName} onChange={handleTextChange}
                error={isFirstNameError} helperText={firstNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Last Name" name="lastName" value={newPatient.lastName} onChange={handleTextChange}
                error={isLastNameError} helperText={lastNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker required disableFuture label="Birth Date" name="birthDate" value={newPatient.birthDate} onAccept={handleDateChange}
                slotProps={{ textField: { variant: 'outlined', error: !!isBirthDateError, helperText: birthDateHelperText } }}
                sx={{ marginTop: '10px', width: '100%' }} onError={(err) => setBirthDateError(err)} />
            </LocalizationProvider>
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Zip Code" name="zipCode" value={newPatient.zipCode} onChange={handleTextChange}
                error={isZipCodeError} helperText={zipCodeHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Email" name="email" value={newPatient.email} onChange={handleTextChange}
                error={isEmailError} helperText={emailHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Phone Number" name="phone" value={newPatient.phone} onChange={handleTextChange}
                error={isPhoneError} helperText={phoneHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
                <FormControl fullWidth error={isMedicationError} style={{ marginTop: '10px' }}>
                    <InputLabel>Medication</InputLabel>
                    <Select label="Medication"name="medication" value={newPatient.medication} onChange={handleTextChange} sx={{ textAlign: 'left' }}> 
                        <MenuItem value="Humira">Humira</MenuItem>
                        <MenuItem value="Enbrel">Enbrel</MenuItem>
                        <MenuItem value="Xeljanz">Xeljanz</MenuItem>
                        <MenuItem value="Cimzia">Cimzia</MenuItem>
                        <MenuItem value="Actemra">Actemra</MenuItem>
                    </Select>
                    {isMedicationError && <FormHelperText>{medicationHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="HCP Name" name="hcpName" value={newPatient.hcpName} onChange={handleTextChange}
                error={isHCPNameError} helperText={hcpNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
                <FormControl fullWidth error={isFRMNameError} style={{ marginTop: '10px' }}>
                    <InputLabel>Assign Patient to FRM</InputLabel>
                    <Select label="Assign Patient to FRM"name="frmName" value={newPatient.frmName} onChange={handleTextChange} sx={{ textAlign: 'left' }}> 
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="Sheila Brown">Sheila Brown</MenuItem>
                        <MenuItem value="Lilly Thompson">Lilly Thompson</MenuItem>
                        <MenuItem value="Ava Mitchell">Ava Mitchell</MenuItem>
                        <MenuItem value="Ethan Ramirez">Ethan Ramirez</MenuItem>
                    </Select>
                    {isFRMNameError && <FormHelperText>{frmNameHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px auto', width: '50rem' }}>
                <FormControl required error={isInsuranceError} component="fieldset" variant="standard" sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start'}} >
                <FormGroup>
                    <TextField required label="Insurance Provider" name="insuranceProvider" value={newPatient.insuranceProvider} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                    <TextField required label="Group Number" name="insuranceGroup" value={newPatient.insuranceGroup} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                    <TextField required label="Member ID" name="insuranceId" value={newPatient.insuranceId} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                </FormGroup>
                {isInsuranceError && <FormHelperText>{insuranceHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ margin: '5px auto', marginBottom: '5px auto', width: '50rem' }}>
                <TextField label="Notes about Patient" name="referralNotes" value={newPatient.referralNotes} onChange={handleTextChange}
                    error={isReferralNotesError} helperText={referralNotesHelperText} style={{ marginTop: '10px' }} multiline rows={2} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
                <FormControl required error={isConsentError} component="fieldset" variant="standard" sx={{ flexDirection: 'row' }}>
                    <FormControlLabel required control= {
                            <Checkbox name="consent" size="large" checked={newPatient.consent} onChange={handleBoolChange} /> }
                        label="Check this box to authorize Flair Health to access the patient's information." sx={{ color: 'gray' }} />
                    {isConsentError && <FormHelperText>{consentHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ margin: '10px auto', width: '20rem' }}>
                <Button variant={'contained'} onClick={handleAddPatient}>Add Patient</Button>
            </div>
        </div>
    )
}

export default function NewCheckForm() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <CheckForm />
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}