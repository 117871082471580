import React, { useState } from 'react';
import logo from '../images/flair_logo.png';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, IconButton, Drawer, useMediaQuery} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { colors } from '../Colors';

export default function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div>
            <div className="header" style={{ display: 'flex', padding: '10px', height: '60px',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: colors.navBarbackground }}>
                <img style={{ height: '3.5rem', cursor: 'pointer' }} src={logo} alt="Logo" onClick={() => navigate('/')} />
    
                {isLargeScreen && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/patients`)}>Patients</Button>
                        <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/analytics`)}>Analytics</Button>
                        <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/settings`)}>Settings</Button>
                    </div>
                )}
                
                {!isLargeScreen && (<IconButton onClick={toggleDrawer} sx={{ color: 'white' }}> <Menu /> </IconButton> )}
            </div>
            
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}
                sx={{ '& .MuiDrawer-paper': 
                    { width: 240, backgroundColor: colors.navBarbackground, color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                    <img style={{ maxWidth: '70%', maxHeight: '70%', width: 'auto', height: 'auto', marginTop: '4%', marginBottom: '10%' }} src={logo} alt="Logo" onClick={() => navigate('/')} />
    
                    <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/`)}>Dashboard</Button>
                    <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/patients`)}>Patients</Button>
                    <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/analytics`)}>Analytics</Button>
                    <Button sx={{ color: 'white', fontSize: 14 }} onClick={() => navigate(`/settings`)}>Settings</Button>
                </div>
            </Drawer>
        </div>
    );
}