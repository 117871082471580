import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import { colors } from '../Colors';
import Navbar from './Navbar'

function BankAccountIntegration() {
    const [bankInfo, setBankInfo] = useState({
        bankName: 'First Medical Bank',
        accountNumber: '123456789012',
        routingNumber: '987654321',
    });

    const [isConnected, setIsConnected] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setBankInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleConnect = () => {
        if (bankInfo.bankName && bankInfo.accountNumber && bankInfo.routingNumber) {
            setIsConnected(true);
            setEditMode(false);
        }
    };

    const handleChangeAccount = () => {
        setEditMode(true);
    };

    return (
        <div>
            <Typography variant="h6" color={colors.tableHeadText} style={{ marginBottom: '10px' }}>
                Bank Account Integration
            </Typography>

            {isConnected && !editMode ? (
                <div>
                    <Typography variant="body1"><strong>Bank Name:</strong> {bankInfo.bankName}</Typography>
                    <Typography variant="body1"><strong>Routing Number:</strong> {bankInfo.routingNumber}</Typography>
                    <Typography variant="body1"><strong>Account Number:</strong> {bankInfo.accountNumber.replace(/\d(?=\d{4})/g, '*')}</Typography>

                    <Button  variant="contained" style={{ marginTop: '20px', backgroundColor: '#293593', color: 'white' }} onClick={handleChangeAccount}>
                      Change Bank Account
                    </Button>
                </div>
            ) : (
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Bank Name" name="bankName" value={bankInfo.bankName}
                            onChange={handleTextChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Account Number" name="accountNumber" value={bankInfo.accountNumber} 
                          onChange={handleTextChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Routing Number" name="routingNumber" value={bankInfo.routingNumber}
                          onChange={handleTextChange} fullWidth required />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button variant="contained" style={{ marginTop: '20px', backgroundColor: '#293593', color: 'white' }} onClick={handleConnect} fullWidth>
                          Connect Bank Account
                        </Button>
                    </Grid>
                </Grid>
            )}

            {isConnected && (
                <Typography variant="body2" color="success.main" style={{ marginTop: '20px' }}>
                    Bank account linked successfully!
                </Typography>
            )}
        </div>
    );
}

function PharmacyInformation() {
  const [pharmacyInfo, setPharmacyInfo] = useState({
      pharmacyName: 'Apex Specialty Pharmacy',
      taxID: '12-3456789',
      npi: '1234567890',
      address: '123 Health St, Suite 200',
      city: 'Medcity',
      state: 'CA',
      zip: '94105',
      phone: '(123) 456-7890',
      email: 'billing@apexspecialtypharmacy.com',
      fax: '(123) 456-7891',
  });

  return (
      <div>
        <Typography variant="h6" color={colors.tableHeadText} style={{ marginBottom: '10px' }}>
            Pharmacy Information
        </Typography>

        <div>
          <Typography variant="body1"><strong>Pharmacy Name: </strong> {pharmacyInfo.pharmacyName}</Typography>
          <Typography variant="body1"><strong>Tax ID:</strong> {pharmacyInfo.taxID}</Typography>
          <Typography variant="body1"><strong>NPI:</strong> {pharmacyInfo.npi}</Typography>
          <Typography variant="body1"><strong>Address:</strong> {pharmacyInfo.address}, {pharmacyInfo.city}, {pharmacyInfo.state} {pharmacyInfo.zip} </Typography>
          <Typography variant="body1"><strong>Phone:</strong> {pharmacyInfo.phone}</Typography>
          <Typography variant="body1"><strong>Email:</strong> {pharmacyInfo.email}</Typography>
          <Typography variant="body1"><strong>Fax:</strong> {pharmacyInfo.fax}</Typography>

          <Button variant="contained" style={{ marginTop: '20px', backgroundColor: '#293593', color: 'white' }}>
            Edit Pharmacy Information
          </Button>
        </div>
    </div>
  );
}

export default function Settings() {
    // const baseUrl = 'http://localhost:4000';
    const baseUrl = 'https://flair-sp-accounts-receivable-tool.vercel.app/';

    return (
    <div style={{ display: 'flex' }}>
    <Navbar />
      <div className="App">
        <Container sx = {{ paddingBottom: '2%'}}>
            <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Settings </Typography>
            <Paper elevation={1} style={{ padding: '20px' }}>
              <PharmacyInformation />
            </Paper>
            <Paper elevation={1} style={{ marginTop: '20px', padding: '20px' }}>
              <BankAccountIntegration />
            </Paper>
        </Container>

        <Container sx = {{ paddingBottom: '3%' }}> </Container>
      </div>
    </div>
  )
}